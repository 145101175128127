// 数据质量分析查询接口
const getDataQualityList = `/gateway/hc-data/datacenter/monitor/page`;
//获取数据源名称
const getSourceList = `/gateway/hc-data/datacenter/source/page`;
// 获取数据模型
const getModelList = `/gateway/hc-data/datacenter/model/page`;
export {
  getDataQualityList,
  getSourceList,
  getModelList
};