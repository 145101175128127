<template>
  <div class="dataQualityAnalysis">
    <v-list
      ref="list"
      :isAdd="true"
      :isOperateColumn="false"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
    >
      <template #headBtnSlot>
        <!-- <v-button text="导入" @click="toAdd"></v-button>
        <v-button text="导出" @click="toAdd"></v-button> -->
        <!-- <v-button text="新增" @click="toAdd"></v-button> -->
      </template>
      <template #searchSlot>
        <div class="item">
          <label>模型名称</label>
          <el-select
            v-model="searchParam.modelId"
            filterable
            remote
            :clearable="true"
            reserve-keyword
            placeholder="请选择模型"
            :remote-method="remoteMethod"
            @change="$refs.list.search()"
          >
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <label>数据源名称</label>
          <el-select
            v-model="searchParam.datasourceId"
            filterable
            remote
            :clearable="true"
            reserve-keyword
            placeholder="请选择数据源"
            :remote-method="remoteMethodSource"
            @change="$refs.list.search()"
          >
            <el-option
              v-for="item in sourceOpt"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>

        <!-- <v-input
          clearable
          v-model="sourceName"
          :placeholder="'请选择数据源'"
          label="数据源名称"
          @blur="handelChange('source')"
        /> -->
      </template>
    </v-list>
  </div>
</template>

<script>
import { getDataQualityList, getSourceList, getModelList } from "./api.js";

export default {
  name: "dataQualityAnalysis",
  data() {
    return {
      searchParam: {
        modelId: "",
        datasourceId: "",
      },
      headers: [
        {
          prop: "modelName",
          label: "模型名称",
        },
        {
          prop: "dataSourceName",
          label: "数据源名称",
        },
        {
          prop: "dataCnt",
          label: "归集数量",
        },
        {
          prop: "dataTime",
          label: "归集时间",
        },
        {
          prop: "errorContent",
          label: "错误信息",
        },
        {
          prop: "errorCnt",
          label: "错误次数",
        },
      ],

      tableUrl: getDataQualityList,
      options: [],
      sourceOpt: [],
    };
  },
  mounted() {},
  methods: {
    //获取模型下拉数据
    remoteMethod(query) {
      if (query !== undefined) {
        query = query.replace(/\s*/g, "");
      }
      const params = {
        curPage: 1,
        pageSize: 10,
        name: query,
      };
      this.$axios.get(`${getModelList}`, { params }).then((res) => {
        if (res.code === 200) {
          const { records } = res.data;
          this.options = records;
        }
      });
    },
    // 获取数据源数据
    remoteMethodSource(query) {
      if (query !== undefined) {
        query = query.replace(/\s*/g, "");
      }
      const params = {
        curPage: 1,
        pageSize: 10,
        type: 0,
        name: query,
      };
      this.$axios.get(`${getSourceList}`, { params }).then((res) => {
        if (res.code === 200) {
          console.log("res=====>", res);
          records;
          const { records } = res.data;
          this.sourceOpt = records;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.dataQualityAnalysis {
  box-sizing: border-box;
  height: 100%;
  .item {
    &:first-child {
      margin: 0 20px;
    }
    label {
      margin-right: 10px;
    }
  }
}
</style>
